<template>
  <v-card
    :loading="organizationsStatus === 'pending' && !currentOrganization"
    class="rounded-0"
    :class="rail ? 'rail-header' : ''"
    :variant="theme.current.value.dark ? 'elevated' : 'tonal'"
    :style="{
      background: theme.current.value.dark
        ? 'linear-gradient(45deg, rgba(255,255,255,0.1) 20%, rgba(255,255,255,0) 100%)'
        : 'linear-gradient(45deg, rgba(255,255,255,0) 20%, rgba(255,255,255,0.2) 100%)',
    }"
  >
    <template #title>
      <nuxt-link v-if="!rail" to="/dashboard">
        <v-img class="logo-image" src="/logo-wit.png" alt="SAM Logo" height="40px" />
      </nuxt-link>

      <v-avatar v-else image="/logo-icon-wit.svg" />
    </template>

    <template #append>
      <v-app-bar-nav-icon v-if="!rail" variant="text" icon="chevron_left" @click="() => emit('update:leftDrawerOpen')" />
    </template>

    <v-menu v-if="organizations?.length > 1 || isSuperAdmin" :close-on-content-click="false">
      <template #activator="{ props: menuProps }">
        <v-list-item v-if="rail" class="font-weight-medium px-2 mb-2 mt-3" v-bind="menuProps">
          <v-tooltip location="end center">
            <template #activator="{ props: tooltipProps }">
              <organization-icon
                :organization="currentOrganization"
                v-bind="{ ...tooltipProps, ...menuProps }"
                style="height: 40px; width: 40px"
              />
            </template>

            <template #default>
              <v-list-item class="text-body-2 font-weight-medium">
                <span class="ellipsis-2-lines">{{ currentOrganization?.description ?? $t("loading") }}</span>

                <template #subtitle>
                  <span class="text-caption">{{ user ? `${user?.firstName} ${user?.lastName}` : $t("unknown") }}</span>
                </template>
              </v-list-item>
            </template>
          </v-tooltip>
        </v-list-item>

        <v-list-item v-else class="text-body-2 font-weight-medium my-2" v-bind="menuProps">
          <template #prepend>
            <organization-icon :organization="currentOrganization" />
          </template>

          <span class="ellipsis-2-lines">{{ currentOrganization?.description ?? $t("unknown") }}</span>

          <template #subtitle>
            <span class="text-caption">{{ user ? `${user?.firstName} ${user?.lastName}` : $t("unknown") }}</span>
          </template>
        </v-list-item>
      </template>

      <template #default>
        <v-card>
          <v-card-title>{{ $t("chooseOrganization") }}</v-card-title>

          <v-card-text>
            <organization-list hide-admin />
          </v-card-text>
        </v-card>
      </template>
    </v-menu>

    <template v-else-if="organizations?.length === 1">
      <v-tooltip v-if="rail" location="end center">
        <template #activator="{ props: tooltipProps }">
          <organization-icon v-bind="tooltipProps" :organization="currentOrganization" class="mx-2 mb-3 mt-4" />
        </template>

        <template #default>
          <v-list-item class="text-body-2 font-weight-medium">
            <span class="ellipsis-2-lines">{{ currentOrganization?.description ?? $t("loading") }}</span>

            <template #subtitle>
              <span class="text-caption">{{ user ? `${user?.firstName} ${user?.lastName}` : $t("unknown") }}</span>
            </template>
          </v-list-item>
        </template>
      </v-tooltip>

      <v-list-item v-else class="text-body-2 font-weight-medium my-2">
        <template #prepend>
          <organization-icon :organization="currentOrganization" />
        </template>

        <span class="ellipsis-2-lines">{{ currentOrganization?.description ?? $t("loading") }}</span>

        <template #subtitle>
          <span class="text-caption">{{ user ? `${user?.firstName} ${user?.lastName}` : $t("unknown") }}</span>
        </template>
      </v-list-item>
    </template>
  </v-card>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify"

const emit = defineEmits(["update:leftDrawerOpen"])
const props = defineProps<{ rail?: boolean; user?: User | null }>()
const { rail, user } = toRefs(props)

const theme = useTheme()
const organizationStore = useOrganizationStore()
const authStore = useAuthStore()

const { currentOrganizations } = storeToRefs(organizationStore)
const { isSuperAdmin } = storeToRefs(authStore)

const currentOrganization = computed(() => currentOrganizations.value[0])

const { data: organizations, status: organizationsStatus } = useLazyAsyncData("organizations-header", organizationStore.getOrganizations, {
  default: () => [],
  immediate: true,
})
</script>

<style lang="scss">
.logo-image .v-img__img--contain {
  object-position: left;
}

.rail-header {
  .v-card-item {
    height: 64px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .v-card-item__content {
      overflow: visible;
    }
  }
}
</style>
